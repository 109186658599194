.sidebar{
    flex: 1;
    background: rgb(250, 250, 250);
    height: 100vh;
    display: flex;
    flex-direction: column;
    border-right: 3px , solid rgb(0, 0, 0);
    
    margin-left: 10px;
    transition: 0.5s;
    .top{  
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgb(0, 85, 111);
        color: white;
        font-size: 14px;
        font-weight: 800;
        position: relative;
        height: 50px;

        span{
            padding: 10px 0px;
        }
        
        .expand{
            width: 30px;
            height: 30px;
            border-radius: 50px;
            position: absolute;
            top: 20;
            right: -10%;
            transform: rotate(180deg);
            transition: 0.3s;
            display: flex;
            justify-content: center;
            align-items: center;

            color: rgb(0, 0, 0);
            background-color: black;
            cursor: pointer;

            background-color: rgb(255, 255, 255);
            -webkit-box-shadow: 1px 1px 5px 0.5px rgba(0, 0, 0, 0.47);
            box-shadow: 1px 2px 5px 0.5px rgba(118, 118, 118, 0.47);

        }
    
    }

    hr{
        height: 0;
        border: 0.5px , solid rgb(245, 241, 241);
        margin-left: 30px;
        margin-right: 30px;
    }

    .body{
        
        ul{
            list-style: none;
            margin: 0;
            padding: 0;
        
            .title{
                font-size: 12px;
                font-weight: bold;
                color: rgb(52, 52, 52);
                margin-top: 15px;
                margin-bottom: 5px;
            }

            li{
                display: flex;
                align-items: center ;
                padding: 1px;
                cursor: pointer;
                color: #888;
                padding-right: 25px;
               

                &:hover{
                    background-color: rgb(0, 85, 111);
                    //border-left: 3px solid cyan;
                    
                    color: rgb(255, 255, 255);
                    transition: 0.3s;
                   
                    .icon{
                        font-size: 22px;
                        color: rgb(243, 243, 243);
                        margin-left: 5px;
                    }
                }

                
                span{
                    font-size: 14px;
                    font-weight: 600;
                    margin-left: 10px;
                }

                .icon{
                    font-size: 22px;
                    color: rgb(70, 70, 70);
                    margin-left: 5px;
                }

            }
        }
    }
}

.sidebarclosed{
    
    width: 50px;
    background: rgb(250, 250, 250);
    height: 100vh;
    display: flex;
    flex-direction: column;
    border-right: 3px , solid rgb(0, 0, 0);
    direction: rtl;
    margin-left: 10px;
    transition: 0.5s;
   
    .top{  
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgb(0, 85, 111);
        color: white;
        font-size: 24px;
        font-weight: 800;
        position: relative;
        height: 50px;
        
        span{
            visibility: collapse;
        }
        .expand{
            width: 30px;
            height: 30px;
            border-radius: 50px;
            position: absolute;
            top: 20;
            left: -5%;
            
            display: flex;
            justify-content: center;
            align-items: center;
           
            transform: rotate(180deg);
            transition: 0.3s;
            
            color: rgb(0, 0, 0);
            background-color: black;
            cursor: pointer;

            background-color: rgb(255, 255, 255);
            -webkit-box-shadow: 1px 1px 5px 0.5px rgba(0, 0, 0, 0.47);
            box-shadow: 1px 2px 5px 0.5px rgba(118, 118, 118, 0.47);

        }
    
    }

    hr{
        height: 0;
        border: 0.5px , solid rgb(245, 241, 241);
    }

    .body{
        
        ul{
            list-style: none;
            margin: 0;
            padding: 0;
        
            .title{
                
                font-size: 12px;
                font-weight: bold;
                color: rgb(52, 52, 52);
                margin-top: 15px;
                margin-bottom: 5px;
                visibility: collapse;
            }

            li{
                display: flex;
                align-items: center ;
                padding: 1px;
                cursor: pointer;
                color: #888;
                padding-right: 15px;
               

                &:hover{
                    background-color: rgb(64, 64, 64);
                    border-right: 5px solid cyan;
                    color: rgb(255, 255, 255);
                    transition: 0.3s;
                   
                    .icon{
                        font-size: 22px;
                        color: rgb(243, 243, 243);
                        margin-left: 5px;
                    }
                }

                
                span{
                    font-size: 14px;
                    font-weight: 600;
                    margin-left: 10px;
                   visibility: collapse;
                }

                .icon{
                    font-size: 22px;
                    color: rgb(70, 70, 70);
                    margin-left: 5px;
                }

            }
        }
    }
}


.datatable{
    height: 600px;
    padding: 20px;

    .datatableTitle{
        width: 100%;
        font-size: 24px;
        color: gray;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-transform: capitalize;

        .link{
            text-decoration: none;
            color: green;
            font-size: 14px;
            font-weight: 400;
            border: 1px solid green;
            padding: 5px 15px;
            border-radius: 5px;
            -webkit-box-shadow: 0.5px 2px 5px 0.5px rgba(0, 0, 0, 0.47);
            box-shadow: 0.5px 2px 5px 0.5px rgba(201, 201, 201, 0.47);

        &:hover{
            color: white;
            background-color: green;
        }


        }
    }

    .cellWithImg{
        display: flex;
        align-items: center;
        .cellImg{
            width: 32px;
            height: 32px;
            border-radius: 50%;
            object-fit: cover;
            margin-left: 20px;
            
        }
    }

    .cellWithStatus{
        padding: 5px;
        border-radius: 5px;
       
          &.active {
            padding: 0px 10px;
            background-color: rgba(0, 128, 0, 0.05);
            color: green;
          }

          &.inactive {
            padding: 0px 10px;
            background-color: rgba(255, 0, 0, 0.05);
            color: crimson;
          }

    }

    .cellAction{
        display: flex;
        align-items: center;
        gap: 15px;

        .viewButton{
            padding: 2px 10px;
            border-radius: 5px;
            color: darkcyan;
            border: 1px solid darkcyan;
            cursor: pointer;
            //box-shadow: 0 0.5px 1px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
        }

        .deleteButton{
            padding: 2px 10px;
            border-radius: 5px;
            color: white;
            background-color: crimson;
            border: 1px solid crimson;
            cursor: pointer;
            //box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
        }
    }
}
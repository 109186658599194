.newuser {
  width: 100%;
  display: flex;
  .error {
    margin: 0;
    color: #bf1616;
  }
  .newContainer {
    flex: 6;

    .top,
    .bottom {
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      padding: 10px;
      margin: 20px;
      display: flex;

      h1 {
        color: lightgray;
        font-size: 20px;
      }

      .left {
        flex: 1;
        text-align: center;

        img {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          object-fit: cover;
          border: 2px solid rgb(202, 202, 202);
        }

        .formInput {
          display: flex;
          justify-content: center;
          label {
            display: flex;
            align-items: center;
            gap: 10px;

            .icon {
              cursor: pointer;
            }
          }
        }
      }

      .right {
        flex: 2;

        form {
          display: flex;
          flex-wrap: wrap;
          gap: 30px;
          justify-content: space-around;

          .formInput {
            width: 40%;
          }

          .submit {
            width: 150px;
            height: 45px;
            padding: 10px;
            border: none;
            border-radius: 5px;
            background-color: rgb(17, 84, 126);
            color: white;
            font-weight: bold;
            cursor: pointer;
            margin-top: 10px;
          }
        }
      }
    }
  }
}

.login {
  .error {
    margin: 0;
    color: #bf1616;
  }
  .container {
    display: flex;
    width: 100vw;
    height: 100vh;

    .left {
      flex: 2;
      background-color: rgb(89, 89, 89);
      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }

    .right {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      .login {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 500px;
        height: 500px;
        background-color: rgb(255, 255, 255);
        border: 2px, solid rgb(98, 98, 98);
        border-radius: 20px;

        -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
        padding: 10px;
        margin: 20px;

        span {
          font-size: 24px;
          font-weight: bold;
          margin-bottom: 30px;
        }

        form {
          display: flex;
          flex-wrap: wrap;
          gap: 15px;
          padding-left: 50px;
          padding-right: 50px;

          .formInput {
            width: 100%;
            display: flex;
            flex-direction: column;

            label {
              font-size: 12px;
            }

            input {
              padding: 10px;
              font-size: 18px;
              border: none;
            }
          }

          .submit {
            width: 50%;
            padding: 10px;
            background-color: #24516b;
            color: rgb(255, 255, 255);
            font-weight: 800;
            font-size: 14px;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            margin-top: 20px;
            &:disabled {
              color: gainsboro;
              cursor: not-allowed;
            }
          }
        }
      }
    }
  }
}

.singlemessage{
    display: flex;
    width: 100%;

    .singleContainer{
        flex: 6;

        .top{
            padding: 20px;
            display: flex;
            gap: 20px;

            .left{
                flex: 1.5;

                -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
                box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
                border-radius: 10px;
                padding: 20px;
                position: relative;

                .editButtom{
                    position: absolute;
                    top: 0;
                    right: 0;
                    padding: 5px;
                    font-size: 12px;
                    color: rgb(11, 120, 132);
                    background-color: rgba(9, 67, 86, 0.34);
                    cursor: pointer;
                }

                .item{
                    display: flex;
                    gap: 15px;

                    .itemImg{
                        width: 100px;
                        height: 100px;
                        border-radius: 50%;
                        object-fit: cover;
                        //border: 0.5px solid rgb(160, 160, 160);
                        -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
                        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
                        animation: zoom-in-zoom-out 1s ease;
                    }

                    .details{
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        
                        .itemTitle{
                            margin-bottom: 10px;
                            color: rgb(148, 148, 148);

                        }

                        .detailItem{
                            margin-bottom: 10px;
                            font-size: 14px;

                            .itemKey{
                                font-weight: bold;
                                color: rgb(148, 148, 148);
                                margin-left: 5px;
                            }

                            .itemValue{
                                font-weight: 300;
                            }
                        }

                        .detailItemMessage{
                            margin-bottom: 10px;
                            font-size: 14px;
                            padding: 20px;
                            border: 1px solid rgb(200, 200, 200);
                            border-radius: 10px;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            gap: 10px;
                            .itemKey{
                                font-weight: bold;
                                color: rgb(148, 148, 148);
                                font-size: large;
                            }

                            .itemValue{
                                font-weight: 300;
                            }
                        }
                    }
                }
            }

            .right{
                flex: 2;

                -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
                box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
                border-radius: 10px;
                padding: 20px;
            }
        }

        .bottom{
            -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
            border-radius: 10px;
            padding: 20px;
        }
    
        .title{
            font-size: 16px;
            margin-bottom: 20px;
            color: lightgray;
        }
    }
}

@keyframes zoom-in-zoom-out {
    0% {
        transform: scale(1.2, 1.2);
    }
 
    100% {
      transform: scale(1, 1);
    }
  }
.table{
    margin-top: 10px;
    

    .cellWarpper{
        display: flex;
        align-items: center;
    }

    .image{
        width: 32px;
        height: 32px;
        border-radius: 50%;
        margin-left: 10px;
        object-fit: cover;
    }

    .status {
        padding: 5px 15px;
        border-radius: 5px;
    
        &.competiteur {
          color: green;
          background-color: rgba(0, 128, 0, 0.151);
          
        }
        &.athlete {
          color: goldenrod;
          background-color: rgba(189, 189, 3, 0.103);
        }

        &.arbitre {
          color: rgb(38, 0, 255);
          background-color: rgba(3, 15, 189, 0.103);
        }
      }

}
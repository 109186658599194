.navbar{
    height: 50px;
    border-bottom: 3px solid rgb(240, 240, 240);
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #555;
   

    .warpper{
        width: 100%;
        display: flex;
        align-items: center;
        padding: 20px;
        justify-content: flex-end;


            .item{
                display: flex;
                align-items: center;
                margin-right: 30px;
                position: relative;
                font-weight: bold;
                font-size: 16px;
                
            }
           
        
    }
}
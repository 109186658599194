.home {
  display: flex;

  .container {
    flex: 6;

    .row1 {
      display: flex;
      flex: 10;
      height: 100%;
      width: 100%;

      .image {
        width: 100%;
        img {
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }
}
